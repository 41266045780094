import React from "react";
import { Grid, Box, Button, Typography, IconButton, Link } from "@mui/material";
import FloatingCard from "../Components/FloatingCard";

import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { contentMargin, contentPadding, bevelRadius} from "../Theme";
import { projectsArray } from './Projects';

const MediaGrid = () => {
  const cardStyle = {
    width: '100%',
    paddingTop: '100%', // maintain aspect ratio
    position: 'relative',
    overflow: 'hidden', // ensure content does not overflow
    borderRadius: 'inherit', // inherit bevels from FloatingCard if necessary
  };

  return (
    <FloatingCard type="invisible" style={{ padding: 0, margin: 0 }}>
      <Grid container spacing={2} style={{ width: 'calc(100% + 16px)', height: '100%', margin: 0, marginLeft: '-8px', marginTop: '-8px', marginBottom: '-8px' }}>
        {projectsArray.map((project) => (
          <Grid key={project.slug} item xs={12} sm={6} md={6} lg={6} style={{ padding: '8px' }}>
            <Link href={`/projects/${project.slug}`} style={{ textDecoration: 'none' }}>
              <Box style={{ ...cardStyle }}>
                <FloatingCard
                  backgroundMedia={project.backgroundMedia}
                  style={{ padding: contentPadding, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                  size=''
                >
                  <Typography variant='h2' style={{ position: 'absolute', left: '40px', top: '40px', color: 'white', zIndex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left' }}>
                    {project.title}
                  </Typography>
                  {/* Button to navigate to the project */}
                  <Box style={{ position: 'absolute', left: '40px', bottom: '40px', zIndex: 1 }}>
                    <Button variant="contained" color="primary" href={`/projects/${project.slug}`}>
                      Learn More
                    </Button>
                  </Box>
                </FloatingCard>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </FloatingCard>
  );
};

export const Home = () => {
  const profileImage = '/images/profile.png';

  return (
    <Grid container spacing={contentMargin} justifyContent="center">
      <FloatingCard backgroundMedia="/projects/black_hole/BlackHole.mp4" type="invisible">
        <Grid item container spacing={2} sx={{ width: '100%' }}>

          {/* Text Grid */}
          <Grid item xs={12} md={6} sx={{ padding: 2 }}>
            <Typography variant="body1" gutterBottom>
              Hello! I'm
            </Typography>
            <Typography variant="h1" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2.5rem' } }}>
              Caelin Leonard
            </Typography>
            <Typography variant="body1" paragraph>
              I create graphics, shaders, 3d models, and tutorials in Blender 3D. This website contains some of my work, enjoy!
            </Typography>
            <Typography variant="body1" paragraph>
              I also post tutorial videos on my youtube channel about Blender and my products.
            </Typography>
          </Grid>

          {/* Image Grid */}
          <Grid item xs={12} md={6} sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
            <Box sx={{
              width: { xs: '80%', md: '100%' },
              height: 0,
              paddingBottom: { xs: '80%', md: '100%' },
              position: "relative",
              borderRadius: bevelRadius
            }}>
              <Box
                component="img"
                src={profileImage}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: bevelRadius
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </FloatingCard>

      <FloatingCard type="invisible" zIndex="1">
        <Grid container justifyContent="center">
          <IconButton aria-label="YouTube" component="a" href="https://www.youtube.com/channel/UCN1RLD-S-9gZ6O-cRhEhiTQ" target="_blank" rel="noopener">
            <YouTubeIcon sx={{ color: 'red', fontSize: '5rem' }} />
          </IconButton>
          <IconButton aria-label="LinkedIn" component="a" href="https://www.linkedin.com/in/caelin-leonard-119107229/" target="_blank" rel="noopener">
            <LinkedInIcon sx={{ color: '#0077b5', fontSize: '5rem' }} />
          </IconButton>
        </Grid>
      </FloatingCard>

      <MediaGrid />
    </Grid>
  );
};

export default Home;
