import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SEO from "./Components/SEO";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import ProjectsRoutes from "./Pages/Projects";

const AppRoutes = () => {
  return (
    <Router>
      <SEO />
      <Routes>
        <Route path="/" element={<Home />} />
        
        {/* Projects Routes */}
        <Route path="/projects/*" element={<ProjectsRoutes />} />

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRoutes;
