import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Grid, Typography, Box, Button } from '@mui/material';
import FloatingCard from '../Components/FloatingCard';

import { ThemeContext, contentMargin } from "../Theme";

export const projectsArray = [
    {
        title: 'Black Hole',
        slug: 'black-hole',
        size: 'medium',
        description: `
        This Blender project brings to life a stunningly realistic black hole. Employing advanced physics simulations, it captures the black hole's mesmerizing gravitational pull and the dynamic motion of its accretion disk. The animation highlights the warping of light and space near the event horizon, showcasing the capabilities of Blender in rendering complex celestial phenomena.
        `,
        links: [],
        backgroundMedia: '/projects/black_hole/BlackHole.mp4',
        mediaGrid: [],
    },
    {
        title: 'Advanced Pixel Shader',
        slug: 'advanced-pixel-shader',
        size: '',
        description: `
        Advanced Pixel Shader is a cutting-edge 3D shader designed for procedural content display. It adeptly simulates realistic images on various screen types by applying sophisticated distortion effects and adjustable pixel sizes. This shader aims to deliver unparalleled realism and versatility in screen image simulation.
        `,
        links: [{ url: '#', text: 'Coming Soon!', disabled: true}],
        backgroundMedia: '/projects/advanced_pixel_shader/pixels3.mp4',
        mediaGrid: [
            { type: 'image', src: '/projects/advanced_pixel_shader/pixels1.mp4', alt: 'Another Image' },
            { type: 'image', src: '/projects/advanced_pixel_shader/pixels2.mp4', alt: 'Another Image' },
            { type: 'image', src: '/projects/advanced_pixel_shader/pixels.mp4', alt: 'Another Image' },
            { type: 'image', src: '/projects/advanced_pixel_shader/pixels4.mp4', alt: 'Another Image' }

        ],
    },
    {
        title: 'Planetary Collection',
        slug: 'planetary-collection',
        size: 'medium',
        description: `
        'Planetary Collection' showcases a series of procedurally generated planets created in Blender. Each planet is uniquely designed using Blender's powerful node-based system, offering a diverse range of environments, colors, and textures. This project demonstrates the versatility of procedural generation in creating detailed and varied celestial bodies.
        `,
        links: [],
        backgroundMedia: '/projects/planetary_collection/planet9.png',
        mediaGrid: [
            { type: 'image', src: '/projects/planetary_collection/planet.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet1.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet2.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet3.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet4.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet5.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet6.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet7.png', alt: 'Another Image' },
            { type: 'image', src: '/projects/planetary_collection/planet8.png', alt: 'Another Image' }
        ],
    },
];

const MediaGrid = ({ mediaGrid }) => {
    const cardStyle = {
        width: '100%',
        paddingTop: '100%',
        position: 'relative'
    };
    // TODO: Make the spacing between the grid items the same as the contentMargin constent
    return (
        <FloatingCard type="invisible" style={{ padding: 0, margin: 0 }}>
            <Grid container spacing={2} style={{ width: 'calc(100% + 16px)', height: '100%', margin: 0, marginLeft: '-8px', marginTop: '-8px', marginBottom: '-8px' }}>
                {mediaGrid.map((media) => (
                    <Grid key={media.id} item xs={12} sm={6} md={6} lg={6} style={{ padding: '8px' }}>
                        <Box style={{ ...cardStyle }}>
                            <FloatingCard
                                backgroundMedia={media.src}
                                type="image"
                                style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </FloatingCard>
    );
};

const SingleProjectDisplay = ({ project }) => {
    const { selectedPalette } = useContext(ThemeContext);

    const renderLinkButtons = (links) => {
        return links.map((link, index) => (
            <Button
                key={index}
                variant="contained"
                color="primary"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                disabled={link.disabled}
                sx={{
                    my: 2,
                    mx: 0.5,
                    display: "inline-block",
                    fontSize: "18px",
                    padding: "8px 16px",
                    color: selectedPalette.colors.text,
                }}
            >
                {link.text}
            </Button>
        ));
    };

    return (
        <Grid container spacing={contentMargin} justifyContent="center">
            <FloatingCard backgroundMedia={project.backgroundMedia} type="invisible" size={project.size} justifyContent="">
                <Grid item container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={12} md={6} sx={{ padding: 2, textAlign: 'left' }}>
                        <Typography variant="h1" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2.5rem' } }}>
                            {project.title}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {project.description}
                        </Typography>
                        {/* Render link buttons */}
                        {renderLinkButtons(project.links)}
                    </Grid>
                </Grid>
            </FloatingCard>

            <MediaGrid mediaGrid={project.mediaGrid} />
        </Grid>
    );
};

const ProjectsRoutes = () => {
    return (
        <Routes>
            {projectsArray.map(project => (
                <Route key={project.slug} path={`${project.slug}`} element={<SingleProjectDisplay project={project} />} />
            ))}
            <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
    );
};

export default ProjectsRoutes;
