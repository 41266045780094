import React, { useContext, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ThemeContext, shadow, bevelRadius, contentPadding } from "../Theme";

const FloatingCard = ({
  children,
  type,
  size,
  zIndex = 0,
  backgroundMedia,
  ...props
}) => {
  const { selectedPalette } = useContext(ThemeContext);

  const hexToRgba = (hex, alpha) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  const colorMap = {
    translucentPrimary: {
      color: hexToRgba(selectedPalette.colors.primary, 0.3),
      defaultZIndex: 1,
      blur: "25px",
    },
    translucentSecondary: {
      color: hexToRgba(selectedPalette.colors.secondary, 0.3),
      defaultZIndex: 1,
      blur: "25px",
    },
    invisible: {
      color: "rgba(255, 255, 255, 0.0)",
      defaultZIndex: -1,
    },
  };

  const sizeConfig = {
    large: { height: "75rem", xs: 12, sm: 12, md: 12 },
    medium: { height: "50rem", xs: 12, sm: 12, md: 12 },
    small: { height: "25rem", xs: 12, sm: 12, md: 12 },
    halfWidth: { height: "25rem", xs: 12, sm: 12, md: 6 },
    default: { xs: 12, sm: 12, md: 12 },
  };

  const { color, blur } = colorMap[type] || {};
  const { height, xs, sm, md } = size ? sizeConfig[size] : sizeConfig.default;

  const isVideo = backgroundMedia?.endsWith('.mp4') || backgroundMedia?.endsWith('.webm');

  const backgroundStyle = {
    ...(backgroundMedia && {
      backgroundImage: isVideo ? undefined : `url(${backgroundMedia})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }),
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (isVideo && videoRef.current) {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started successfully.
        }).catch(error => {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
      }
    }
  }, [isVideo]);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: color,
          borderRadius: bevelRadius,
          boxShadow: type === "invisible" ? "none" : shadow,
          ...(height && { height }),
          backdropFilter: blur ? `blur(${blur})` : "none",
          padding: contentPadding,
          display: 'flex',
          flexDirection: 'column',
          ...props.sx,
          ...backgroundStyle,
        }}
        {...props}
      >
        {isVideo && (
          <video
            ref={videoRef}
            loop
            muted
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 'inherit',
              overflow: 'hidden'
            }}
          >
            <source src={backgroundMedia} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <Box style={{ position: 'relative', zIndex: zIndex + 1, ...props.style }}>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

export default FloatingCard;
