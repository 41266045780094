import React, { useEffect, useMemo, createContext, useState } from "react";

import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

export const themeModes = {
  light: {
    name: "light",
    primary: "#D6D6D6",
    text: "#141414",
  },
  dark: {
    name: "dark",
    primary: "#020814",
    text: "#D6D6D6",
  }
}

export const PalettesOptions = (selectedThemeMode) => {

  const palettes = {

    // ### Dark Themes ###
    // https://coolors.co/141414-a30029-660019-666666-d6d6d6
    red: {
      name: "red",
      colors: {
        primary: "#2b2d42",
        secondary: "#8d99ae",
        tertiary: "#8d99ae",
        quinary: "#666666",
        text: selectedThemeMode.text,
      },
      materials: {
        primaryMaterial: {
          color: selectedThemeMode.primary,
          roughness: 0.5,
          metalness: 0.2,
        },
        secondaryMaterial: {
          color: "#A30029",
          roughness: 0.8,
          metalness: 0,
          emissiveIntensity: 0,
          emissive: "#A30029",
        },
      },
    }
  }

  return palettes
};

const defaultThemeMode = themeModes.dark
const defaultPalette = PalettesOptions(defaultThemeMode).red;

export const ThemeContext = createContext({
  selectedThemeMode: defaultThemeMode,
  selectedPalette: defaultPalette,
  setSelectedThemeMode: () => { },
  setSelectedPalette: () => { },
});

// Other tweakables
export const shadow = "0px 4px 6px rgba(0, 0, 0, 0.1)"
export const bevelRadius = "20px"
export const pagePadding = "4%"
export const contentPadding = "4%"
export const contentMargin = "4%"

export const hexToRgba = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const ThemeDefinition = (selectedPalette) => {
  const backgroundColor = selectedPalette?.colors?.secondary
    ? hexToRgba(selectedPalette.colors.secondary, 0.3)
    : 'rgba(0,0,0,0.3)';

  return createTheme({
    palette: {
      primary: {
        main: selectedPalette.colors.primary,
      },
      secondary: {
        main: selectedPalette.colors.secondary,
      },
      text: {
        primary: selectedPalette.colors.text,
        secondary: selectedPalette.colors.text,
        disabled: selectedPalette.colors.text,
        hint: selectedPalette.colors.text,
      },
    },
    typography: {
      fontFamily:
        "'Poppins', 'Inter', 'Fira Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      h1: {
        fontWeight: 700,
        fontSize: "3.5rem",
        color: selectedPalette.colors.text,
      },
      h2: {
        fontWeight: 600,
        fontSize: "2rem",
        color: selectedPalette.colors.text,
      },
      h3: {
        fontWeight: 500,
        fontSize: "1.75rem",
        color: selectedPalette.colors.text,
      },
      body1: {
        fontWeight: 500,
        fontSize: "1.25rem",
        color: selectedPalette.colors.text,
      },
      body2: {
        fontWeight: 400,
        fontSize: "0.875rem",
        color: selectedPalette.colors.text,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundImage:
              `linear-gradient(to top right, ${selectedPalette.colors.secondary}, ${selectedPalette.colors.primary}, ${selectedPalette.colors.primary})`,
            backgroundAttachment: 'fixed',
            minHeight: "100vh",
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: selectedPalette.colors.tertiary,
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track-piece': {
              background: selectedPalette.colors.primary,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: selectedPalette.colors.secondary,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: selectedPalette.colors.text,
            transition: 'background-color 0.3s ease-in',
            '&:hover': {
              backgroundColor: selectedPalette.colors.tertiary,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: backgroundColor,
            borderRadius: bevelRadius,
            boxShadow: shadow,
            backdropFilter: "blur(25px)",
          }
        }
      }
    },
  });
};

export const ThemeWrapper = ({ children }) => {
  const [selectedThemeMode, setSelectedThemeMode] = useState(themeModes[localStorage.getItem('themeMode')] || defaultThemeMode);
  const [selectedPalette, setSelectedPalette] = useState(PalettesOptions(selectedThemeMode)[localStorage.getItem('palette')] || defaultPalette);

  useEffect(() => {
    localStorage.setItem('themeMode', selectedThemeMode.name);
  }, [selectedThemeMode]);

  useEffect(() => {
    localStorage.setItem('palette', selectedPalette.name);
  }, [selectedPalette]);

  const contextValue = useMemo(() => ({
    selectedThemeMode,
    selectedPalette,
    setSelectedThemeMode,
    setSelectedPalette
  }), [selectedThemeMode, selectedPalette]);

  const theme = ThemeDefinition(selectedPalette)

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        {/* <ThemeSelector /> */}
        <CssBaseline />
        <Box padding={pagePadding}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeWrapper;
